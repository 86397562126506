import {
  CodeCardData,
  CodeTaskCreationCard,
  SharedMessage as SharedMessageType,
} from 'src/types';
import { useRef } from 'react';
import { CodeSources } from './components/CodeSources';

interface CoderCardProps {
  message: SharedMessageType;
}
export const CoderCard = ({ message }: CoderCardProps) => {
  const { code_card = { data: {} } } = message.payload as CodeTaskCreationCard;
  const { code_snippets = {} } = code_card.data as CodeCardData;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="nj-thread-code-card" ref={containerRef}>
      <CodeSources codeSnippets={code_snippets} />
    </div>
  );
};
