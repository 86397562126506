import {
  ChitChatCard,
  ExternalModelReference,
  SharedMessage as SharedMessageType,
} from 'src/types';
import { MessageContent } from '../MessageContent';
import { Summary } from '../Summary';
import { ExternalModelReferencesList } from 'src/components/ExternalModelReferencesList';
import { useMemo } from 'react';
import { prepareExternalModelsReferences } from 'src/utils';

interface ChatCardProps {
  message: SharedMessageType;
}

export const ChatCard = ({ message }: ChatCardProps) => {
  const { data } = message.payload as ChitChatCard;

  const { referenced_model_summary } = data || {};

  const { references, summary } = referenced_model_summary || {};

  const ninja_model_output = data.ninja_model_output || '';

  const externalSources: ExternalModelReference[] = useMemo(
    () => prepareExternalModelsReferences(references || {}),
    [references]
  );

  return (
    <div>
      {ninja_model_output && <MessageContent content={ninja_model_output} />}
      <br />
      {data.referenced_model_summary && (
        <>
          <Summary
            title="Summary from external models"
            content={summary || ''}
            colorPalette="light-blue"
            references={externalSources}
          />

          <ExternalModelReferencesList references={externalSources} />
        </>
      )}
    </div>
  );
};
