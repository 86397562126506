import { CaretUp, CaretDown } from '@phosphor-icons/react';

const SVG_SIZE = 20;

interface CollapsedButtonProps {
  isExpanded: boolean;
  className?: string;
  iconSize?: number;
  onClick?: () => void;
}

export const CollapsedButton = ({
  isExpanded,
  className,
  iconSize = SVG_SIZE,
  onClick,
}: CollapsedButtonProps) => {
  return (
    <button className={className} onClick={onClick} type="button">
      {isExpanded ? <CaretUp size={iconSize} /> : <CaretDown size={SVG_SIZE} />}
    </button>
  );
};
