import { useMemo } from 'react';
import {
  SharedMessage as SharedMessageType,
  ResearchCardData,
  ResearchTaskCreationCard,
  ExternalModelReference,
} from 'src/types';
import { ResearchSummary } from './components/ResearchSummary';
import { ResearchMedia } from './components/ResearchMedia';
import { ResearchDeepResearchSection } from 'src/components/SharedMessage/components/ResearcherCard/components/ResearchDeepResearchSection';
import { Summary } from 'src/components/SharedMessage/components/Summary';
import { ExternalModelReferencesList } from 'src/components/ExternalModelReferencesList';
import { ThreadResearchReferences } from 'src/components/ThreadResearchReferences/ThreadResearchReferences';
import { prepareExternalModelsReferences } from 'src/utils';

type ResearcherCardProps = {
  message: SharedMessageType;
};

export const ResearcherCard = ({ message }: ResearcherCardProps) => {
  const { research_card = { data: {} } } =
    message.payload as ResearchTaskCreationCard;

  const { search_result, deep_research_result, generative_ai_result } =
    research_card.data as ResearchCardData;

  const { referenced_model_summary } = generative_ai_result || {};

  const {
    web_page_references = [],
    image_references,
    video_references,
  } = search_result || {};

  const externalSources: ExternalModelReference[] = useMemo(
    () =>
      prepareExternalModelsReferences(
        referenced_model_summary?.references || {},
      ),
    [referenced_model_summary?.references],
  );

  return (
    <div className="nj-thread-research-card">
      <ResearchSummary
        title="Ninja"
        summary={search_result?.summary}
        hasIcon={true}
        references={web_page_references}
      />

      <ThreadResearchReferences researchReferences={web_page_references} />

      {deep_research_result && (
        <>
          <ResearchDeepResearchSection
            content={deep_research_result?.summary}
            references={deep_research_result.web_page_references}
          />

          <ThreadResearchReferences
            researchReferences={deep_research_result.web_page_references}
          />
        </>
      )}

      <ResearchMedia
        images={image_references || []}
        videos={video_references || []}
      />

      {generative_ai_result && (
        <>
          <Summary
            content={referenced_model_summary?.summary || ''}
            title="Summary from external models"
            colorPalette="light-blue"
            references={externalSources}
          />
          <ExternalModelReferencesList references={externalSources} />
        </>
      )}
    </div>
  );
};
