import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Check, LinkSimple, ShareFat, IconWeight } from '@phosphor-icons/react';
import {
  ControlledMenu,
  MenuDivider,
  useClick,
  useMenuState,
} from '@szhsin/react-menu';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { FacebookIcon } from 'src/images/icons/facebook';
import { LinkedinIcon } from 'src/images/icons/linkedin';
import { WhatsappIcon } from 'src/images/icons/whatsapp';
import { EnvelopeIcon } from 'src/images/icons/envelope';
import { XIcon } from 'src/images/icons/twitter';
import { toast } from 'react-toastify';

type ShareButtonProps = {
  iconSize?: number;
  className?: string;
  weight?: IconWeight;
};

const ICON_SIZE = 24;
const ICON_SIZE_SMALL = 20;

export const ShareButton = ({
  iconSize = ICON_SIZE,
  weight = 'regular',
  className,
}: ShareButtonProps) => {
  const [{ state }, toggleMenu] = useMenuState();

  const ref = useRef(null);
  const anchorProps = useClick(state, toggleMenu);

  const location = useLocation();
  const shareLink = `${window.location.origin}/#${location.pathname}`;

  const handleButtonClick = () => {
    navigator.clipboard.writeText(shareLink);
    toast(
      <div className="nj-copy-snackbar-content">
        <Check size={ICON_SIZE} />
        <span>Copied</span>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        className: 'nj-copy-snackbar',
        autoClose: 1000,
        closeButton: false,
        hideProgressBar: true,
      }
    );
  };

  return (
    <div>
      <button
        data-e2e="sharing-message-menu-button"
        className={className}
        {...anchorProps}
        type="button"
        ref={ref}
      >
        <ShareFat size={iconSize} weight={weight} />
      </button>

      <ControlledMenu
        state={state}
        captureFocus
        arrow={false}
        gap={-25}
        position={'anchor'}
        menuClassName="nj-multi-menu"
        anchorRef={ref}
        onClose={() => toggleMenu(false)}
        portal={state === 'open' || state === 'opening'}
        transition
        direction="left"
        shift={24}
      >
        <div className="nj-share--modal">
          <h5 className="nj-share--title">Public link to share</h5>
          <div className="nj-share--copy-block">
            <a
              className="nj-share--copy-field-link"
              href={shareLink}
              target="_blank"
              rel="noreferrer"
            >
              {shareLink.replace('https://', '').replace('http://', '')}
            </a>
            <button
              className="nj-share--copy-button"
              type="button"
              onClick={handleButtonClick}
            >
              <LinkSimple size={ICON_SIZE_SMALL} /> Copy link
            </button>
          </div>

          <MenuDivider />

          <div className="nj-share--buttons-block">
            <EmailShareButton type="button" url={shareLink}>
              <EnvelopeIcon />
            </EmailShareButton>

            <FacebookShareButton type="button" url={shareLink}>
              <FacebookIcon />
            </FacebookShareButton>

            <LinkedinShareButton type="button" url={shareLink}>
              <LinkedinIcon />
            </LinkedinShareButton>

            <TwitterShareButton type="button" url={shareLink}>
              <XIcon />
            </TwitterShareButton>

            <WhatsappShareButton type="button" url={shareLink}>
              <WhatsappIcon />
            </WhatsappShareButton>
          </div>
        </div>
      </ControlledMenu>
    </div>
  );
};
