import { Footer } from '../Footer';
import Ninja from '../../images/metahuman/ninja.png';

const IMG_SIZE = 160;

export const NotFoundPage = () => {
  return (
    <div className="nj-not-found-page">
      <img
        src={Ninja}
        alt="Ninja"
        width={IMG_SIZE}
        height={IMG_SIZE}
        className="nj-not-found-page--image"
      />
      <h1 className="nj-not-found-page--title">
        The link you are trying to access has expired or does not exist.
      </h1>
      <Footer />
    </div>
  );
};
