import { isString, isObject, ExternalModelReference } from 'src/types';
import { uppercaseFirstLetter } from './general';
import { AiGenerationData } from 'src/types/models/AiGenerationData';
import { externalModelsSet, imageGeneratorsModelsSet } from 'src/constants';
import type { ImageGenData } from 'src/types/models/ImageGenData';

/**
 * getReadableKey() returns readable key.
 * @param key string
 * @returns string
 */
export function getReadableKey(key: string): string {
  return key
    .split('_')
    .map((word) => uppercaseFirstLetter(word))
    .join(' ');
}

/**
 * flattenObject() converts message to a readable format.
 * @param obj Record
 * @param indent number
 * @returns string
 */
export const OMIT_MESSAGE_KEYS = [
  'data',
  'metadata_info',
  'actions',
  'search_result',
  'summary',
  'snippet',
  'generative_ai_result',
  'content',
  'code_snippets',
  'raw_text',
  'references',
  'web_page_references',
  'combined_references',
  'key',
];

export const IGNORE_MESSAGE_KEYS = [
  'query',
  'payload_type',
  'width',
  'height',
  'progress',
  'version',
  'model',
  'words_written_count',
  'words_read_count',
  'domain',
  'source',
  'favicon',
  'title',
  'order',
];

export function flattenObject(
  obj: Record<string, unknown>,
  indent = 0,
): string {
  let result = '';

  for (const key in obj) {
    if (!IGNORE_MESSAGE_KEYS.includes(key)) {
      const readableValue = isString(obj[key])
        ? uppercaseFirstLetter(obj[key] as string)
        : obj[key];
      const readableKey = OMIT_MESSAGE_KEYS.includes(key)
        ? ''
        : `${''.repeat(indent)}${getReadableKey(key)}: \n`;

      if (Array.isArray(readableValue)) {
        for (const [index, item] of readableValue.entries()) {
          if (isObject(item)) {
            if (!OMIT_MESSAGE_KEYS.includes(key)) {
              result += `${''.repeat(indent)}${key}[${index + 1}]:\n`;
            }
            result += `${flattenObject(
              item as Record<string, unknown>,
              indent + 1,
            )}`;
          } else {
            result += `${''.repeat(indent)}${key}[${index + 1}]: ${item}\n`;
          }
        }
      } else if (isObject(readableValue)) {
        result += `${readableKey}${flattenObject(
          readableValue as Record<string, unknown>,
          indent + 2,
        )}`;
      } else {
        result += `${''.repeat(indent)}${readableKey} ${readableValue}\n\n`;
      }
    }
  }

  return result;
}

export const prepareExternalModelsReferences = (
  references: Record<string, AiGenerationData>,
): ExternalModelReference[] => {
  return (
    Object.entries(references)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, { model }]) => model && externalModelsSet[model])
      .map(([key, { content, model }]) => {
        return {
          modelKey: key,
          content: content || '',
          icon: model ? externalModelsSet[model].icon_name : null,
          title: model ? externalModelsSet[model].display_name : '',
          url: model || '',
        };
      })
  );
};

export const prepareGeneratedImages = (
  images: Record<string, ImageGenData>,
) => {
  return Object.entries(images).map(([key, image]) => {
    return {
      id: key,
      url: image?.image_url || '',
      icon:
        image.model && imageGeneratorsModelsSet[image.model]
          ? imageGeneratorsModelsSet[image.model]?.icon_name
          : null,
      title:
        imageGeneratorsModelsSet[image?.model || '']?.display_name ||
        image?.model ||
        '',
      height: 200,
      width: 200,
      source: image?.model || '',
      status: image?.status || '',
    };
  });
};
