import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  ModalGalleryType,
  ExternalModelReference as ExternalModelReferenceType,
} from 'src/types';
import { ExternalModelReference } from './components/ExternalModelReference';
import { CollapsibleReferencesList } from 'src/components/CollapsibleReferencesList/CollapsibleReferencesList';

interface ExternalModelReferencesListProps {
  references?: ExternalModelReferenceType[];
}

export const ExternalModelReferencesList = ({
  references = [],
}: ExternalModelReferencesListProps) => {
  const { onChangeModalGalleryData } = useContext(SessionContext);

  const handleClick = (key: string) => {
    onChangeModalGalleryData(
      true,
      ModalGalleryType.EXTERNAL_MODELS,
      references,
      Number(key) - 1
    );
  };

  return (
    <CollapsibleReferencesList
      title="Models"
      references={references}
      renderItem={(props) => (
        <ExternalModelReference
          {...props}
          key={props.url}
          onClick={handleClick}
        />
      )}
    />
  );
};
