import { CodeSourceTypes } from 'src/types';
import type { CodeContent } from 'src/types/models/CodeContent';
import { CodeSource } from '../CodeSource';
import { sourcesSet } from 'src/constants/sourcesSet';

interface CodeSourcesProps {
  codeSnippets?: Record<string, CodeContent>;
}

export const CodeSources = ({ codeSnippets }: CodeSourcesProps) => {
  if (!codeSnippets) {
    return null;
  }

  const sources = Object.entries(codeSnippets).map(([key, value]) => ({
    key,
    content: value.raw_text,
    ...sourcesSet[key as CodeSourceTypes],
    title:
      value.model?.human_friendly_name ||
      sourcesSet[key as CodeSourceTypes].title,
  }));

  return (
    <ul>
      {sources
        .slice()
        .sort((a, b) => a.order - b.order)
        .map(({ key, ...item }, index) => (
          <CodeSource key={key} isExpanded={index === 0} {...item} />
        ))}
    </ul>
  );
};
